import React from 'react';

import Input from '../Inputs/Input/Input';
import Button from '../Inputs/Button/Button';

const Step3 = (props) => {
	const { values, handleChange, error } = props;

	const prevStep = (e) => {
		e.preventDefault();
		props.prevStep();
	};

	return (
		<div className="row-main">
			<div className="register-form">
				<h2 className="register-form__title small-padding">Heslo</h2>
				<p className="register-form__text register-form__text--large">
					Zvolte si heslo pro přístup k Vašemu portfoliu
				</p>
				<div className="row-main row-main--thinest">
					<Input
						label="Heslo"
						type="password"
						name="password"
						onChange={handleChange('password')}
						defaultValue={values.password}
					/>
					<Input
						label="Zopakujte heslo"
						type="password"
						name="confirmationPassword"
						onChange={handleChange('passwordConfirm')}
						defaultValue={values.passwordConfirm}
					/>
					{error && <p className="input-input__error-form">Vyplňte chybějící pole</p>}
					<Button type="submit" text="Založit účet" />
					<div className="u-text-center">
						<button
							className="register-form__go-back"
							type="button"
							onClick={prevStep}
						>
							Zpět
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Step3;
