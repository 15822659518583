import React from 'react';
import { useDispatch } from 'react-redux';
import { updateDeliveryStamp } from '../../actions/stampsActions';

import Button from '../Inputs/Button/Button';

const SetAsDeliveredModal = ({ stampId, userId }) => {
	const dispatch = useDispatch();

	const handleOnConfirm = (e) => {
		e.preventDefault();
		dispatch(updateDeliveryStamp(stampId, 'delivered', userId));
	};

	return (
		<div className="modal__overlay">
			<div className="modal__block">
				<h2 className="modal__title">Označit položku jako doručenou?</h2>
				<button
					className="modal__close"
					onClick={() => {
						dispatch({ type: 'MODAL_CLOSE' });
					}}
				>
					×
				</button>
				<Button
					text="Potvrdit"
					onClick={(e) => {
						handleOnConfirm(e);
						dispatch({ type: 'MODAL_CLOSE' });
					}}
				/>
			</div>
		</div>
	);
};

export default SetAsDeliveredModal;
