const initialState = {
	isLoading: false,
	type: null,
	message: null,
};

const alertReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'ALERT_LOADING':
			return {
				...state,
				isLoading: true,
			};
		case 'ALERT_PROFILE_CHANGE_S':
			return {
				...state,
				isLoading: false,
				type: 'success',
				message: 'Údaje byly úspěšně upraveny',
			};
		case 'ALERT_PROFILE_CHANGE_E':
			return {
				...state,
				isLoading: false,
				type: 'error',
				message: 'Při úpravě údajů došlo k chybě',
			};
		case 'ALERT_RESET_PASSWORD_S':
			return {
				...state,
				isLoading: false,
				type: 'success',
				message: 'Změna hesla proběhla úspěšně. Prosím, přihlašte se',
			};
		case 'ALERT_RESET_PASSWORD_E':
			return {
				...state,
				isLoading: false,
				type: 'error',
				message: 'Při změně hesla došlo k chybě',
			};
		case 'ALERT_PROFILE_CHANGE_PASSWORD_S':
			return {
				...state,
				isLoading: false,
				type: 'success',
				message: 'Změna hesla proběhla úspěšně. Prosím, přihlašte se znovu',
			};
		case 'ALERT_PROFILE_CHANGE_PASSWORD_E':
			return {
				...state,
				isLoading: false,
				type: 'error',
				message: 'Při změně hesla došlo k chybě',
			};
		case 'ALERT_REFUND_MONEY_S': {
			return {
				...state,
				isLoading: false,
				type: 'success',
				message: 'Žádost o vácení peněz byla odeslána',
			};
		}
		case 'ALERT_REFUND_MONEY_E': {
			return {
				...state,
				isLoading: false,
				type: 'error',
				message:
					action.payload || 'Při žádosti o vrácení peněz se vyskytla chyba',
			};
		}
		case 'ALERT_FORGOT_PASSWORD_S':
			return {
				...state,
				isLoading: false,
				type: 'success',
				message: action.payload,
			};
		case 'ALERT_FORGOT_PASSWORD_E':
			return {
				...state,
				isLoading: false,
				type: 'error',
				message: action.payload,
			};
		case 'ALERT_LOGIN_E':
			return {
				...state,
				isLoading: false,
				type: 'error',
				message: action.payload,
			};
		case 'ALERT_BUY_STAMP_S':
			return {
				...state,
				isLoading: false,
				type: 'success',
				message: 'Gratulujeme k nové známce',
			};
		case 'ALERT_BUY_STAMP_E':
			return {
				...state,
				isLoading: false,
				type: 'error',
				message: action.payload || 'Položku zatím nelze koupit',
			};
		case 'ALERT_RESET':
			return {
				...state,
				isLoading: false,
				type: null,
				message: null,
			};
		default:
			return state;
	}
};

export default alertReducer;
