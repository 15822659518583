import React from 'react';
import ForgotPasswordFrom from '../components/ForgotPasswordForm/ForgotPasswordForm';

const ForgotPassword = () => {
	return (
		<>
			<ForgotPasswordFrom />
		</>
	);
};

export default ForgotPassword;
