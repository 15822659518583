import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Input from '../Inputs/Input/Input';
import Button from '../Inputs/Button/Button';
import { updateUser, changePassword } from '../../actions/authActions';

import Alert from '../Alert';

const ProfileForm = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.authReducers.user);
	const alert = useSelector((state) => state.alertReducers);

	const [firstName, setFirstName] = useState(user.firstName);
	const [lastName, setLastName] = useState(user.lastName);
	const [email, setEmail] = useState(user.email);
	const [phone, setPhone] = useState(user.phone);
	const [street, setStreet] = useState(user.address.street);
	const [streetNumber, setStreetNumber] = useState(user.address.streetNumber);
	const [city, setCity] = useState(user.address.city);
	const [postCode, setPostCode] = useState(user.address.postCode);
	const [account, setAccount] = useState(user.account);

	const [passwordCurrent, setPasswordCurrent] = useState();
	const [password, setPassword] = useState();
	const [passwordConfirm, setPasswordConfirm] = useState();

	const handleOnChange = (e) => {
		const { name, value } = e.target;

		switch (name) {
			case 'firstName':
				setFirstName(value);
				break;
			case 'lastName':
				setLastName(value);
				break;
			case 'email':
				setEmail(value);
				break;
			case 'phone':
				setPhone(value);
				break;
			case 'street':
				setStreet(value);
				break;
			case 'streetNumber':
				setStreetNumber(value);
				break;
			case 'city':
				setCity(value);
				break;
			case 'postCode':
				setPostCode(value);
				break;
			case 'account':
				setAccount(value);
				break;
			case 'currentPassword':
				setPasswordCurrent(value);
				break;
			case 'password':
				setPassword(value);
				break;
			case 'confirmationPassword':
				setPasswordConfirm(value);
				break;
			default:
				break;
		}
	};

	const handleOnUpdateData = (e) => {
		e.preventDefault();
		dispatch(
			updateUser({
				firstName,
				lastName,
				email,
				phone,
				street,
				streetNumber,
				city,
				postCode,
				account,
			})
		);
	};

	const handleOnUpdatePassword = (e) => {
		e.preventDefault();
		dispatch(
			changePassword({
				passwordCurrent,
				password,
				passwordConfirm,
			})
		);
	};

	return (
		<>
			{alert.message ? <Alert type={alert.type} message={alert.message} /> : ''}
			<div className="row-main">
				<div className="profile">
					<h2 className="profile__title">Moje údaje</h2>
					<form className="form" onSubmit={(e) => handleOnUpdateData(e)}>
						<div className="row-main row-main--thinest">
							<div className="register-form__split-box">
								<Input
									label="Vaše jméno"
									type="text"
									name="firstName"
									defaultValue={firstName}
									onChange={(e) => handleOnChange(e)}
								/>
								<Input
									label="Vaše příjmení"
									type="text"
									name="lastName"
									defaultValue={lastName}
									onChange={(e) => handleOnChange(e)}
								/>
							</div>
							<Input
								label="Email"
								type="email"
								name="email"
								defaultValue={email}
								onChange={(e) => handleOnChange(e)}
							/>
							<Input
								label="Tel. číslo"
								type="tel"
								name="phone"
								defaultValue={phone}
								onChange={(e) => handleOnChange(e)}
							/>
							<div className="register-form__split-box">
								<Input
									label="Ulice"
									type="text"
									name="street"
									defaultValue={street}
									onChange={(e) => handleOnChange(e)}
								/>
								<Input
									label="Číslo popisné"
									type="text"
									name="streetNumber"
									defaultValue={streetNumber}
									onChange={(e) => handleOnChange(e)}
								/>
							</div>
							<div className="register-form__split-box">
								<Input
									label="Město"
									type="text"
									name="city"
									defaultValue={city}
									onChange={(e) => handleOnChange(e)}
								/>
								<Input
									label="PSČ"
									type="text"
									name="postCode"
									defaultValue={postCode}
									onChange={(e) => handleOnChange(e)}
								/>
							</div>
							<Input
								label="Číslo účtu"
								type="text"
								name="account"
								defaultValue={account}
								onChange={(e) => handleOnChange(e)}
							/>
							<Button type="submit" text="Uložit změny" />
						</div>
					</form>
					<form className="form" onSubmit={handleOnUpdatePassword}>
						<div className="row-main row-main--thinest">
							<Input
								label="Aktuální heslo"
								type="password"
								name="currentPassword"
								onChange={(e) => handleOnChange(e)}
							/>
							<Input
								label="Nové heslo"
								type="password"
								name="password"
								onChange={(e) => handleOnChange(e)}
							/>
							<Input
								label="Znovu nové heslo"
								type="password"
								name="confirmationPassword"
								onChange={(e) => handleOnChange(e)}
							/>
							<Button type="submit" text="Změnit heslo" />
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default ProfileForm;
