import React, { useState } from 'react';

const Alert = ({ type, message }) => {
	const [hide, setHide] = useState(true);

	setTimeout(() => {
		setHide(false);
	}, 2000);

	return hide ? <div className={`alert alert--${type}`}>{message}</div> : '';
};

export default Alert;
