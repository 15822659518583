const initialState = {
	isOpen: false,
	type: null,
	stampId: null,
};

const modalReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'MODAL_BUY':
			return {
				...state,
				isOpen: 'true',
				type: 'buymodal',
				stampId: action.payload,
			};
		case 'MODAL_DELIVERED':
			return {
				...state,
				isOpen: 'true',
				type: 'deliveredmodal',
				stampId: action.payload.stampId,
				userId: action.payload.userId,
			};
		case 'MODAL_CLOSE':
			return {
				...state,
				isOpen: 'false',
				type: 'null',
				stampId: null,
			};
		default:
			return state;
	}
};

export default modalReducer;
