import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TransactionsTable from '../TransactionsTable';
import Modal from '../Modals/RefundMoneyModal';
import Alert from '../Alert';

const Transaction = () => {
	const [modalOpen, setModalOpen] = useState(false);

	const user = useSelector((state) => state.authReducers.user);
	const alert = useSelector((state) => state.alertReducers);

	let lastPayment;
	for (const el of user.payments) {
		if (el.variableSymbol !== 0 && el.variableSymbol !== 1) {
			lastPayment = el.dateOfPayment;
		}
	}

	return (
		<>
			{alert.message ? <Alert type={alert.type} message={alert.message} /> : ''}
			<div className="transactions">
				<div className="row-main row-main--thinest">
					<h1 className="transactions__title">Transakce</h1>

					<div className="transactions__payments">
						<div className="transactions__payments-qr">
							<img
								src={`https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=41217329&bankCode=5500&amount=${user.info.amount}.00&currency=CZK&vs=${user.variableSymbol}&branding=false`}
								alt="QR platba"
							/>
						</div>
						<div className="transactions__payments-info">
							<div className="transactions__payments-item">
								<p>
									<strong>Číslo účtu:</strong>
								</p>
								<p>41217329/5500</p>
							</div>
							<div className="transactions__payments-item">
								<p>
									<strong>Variabilní symbol:</strong>
								</p>
								<p>{user.variableSymbol}</p>
							</div>
							<div className="transactions__payments-item">
								<p>
									<strong>Částka:</strong>
								</p>
								<p>{user.info.amount} Kč</p>
							</div>
							<div className="transactions__payments-item">
								<p>
									<strong>Poslední platba:</strong>
								</p>
								<p>
									{user.payments.length
										? new Date(lastPayment).toLocaleDateString()
										: '-'}
								</p>
							</div>
						</div>
					</div>

					<TransactionsTable user={user} />

					<p className="transactions__refund">
						Naspořené peníze můžete{' '}
						<span
							onClick={() => {
								setModalOpen(true);
							}}
						>
							vybrat i bez investice
						</span>{' '}
						do známky.
					</p>
				</div>
				{modalOpen && <Modal setOpenModal={setModalOpen} />}
			</div>
		</>
	);
};

export default Transaction;
