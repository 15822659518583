import React from 'react';
import { nanoid } from 'nanoid';

const TransactionsTable = ({ user }) => {
	const startWith = (price) => {
		let stringPrice = price.toString();
		if (stringPrice.startsWith('-')) {
			const replaceMinus = stringPrice.replace('-', '');
			return (
				<td className="transactions-table__amount negative">
					{replaceMinus} Kč
				</td>
			);
		} else {
			return <td className="transactions-table__amount">{price} Kč</td>;
		}
	};

	const infoMessage = (payment) => {
		if (payment.variableSymbol === 0) {
			return <td>Žádost o vrácení peněz</td>;
		} else if (payment.variableSymbol === 1) {
			return <td>Nákup položky</td>;
		} else {
			return <td>Příchozí platba</td>;
		}
	};

	return (
		<div className="overflow-element">
			<table className="transactions-table">
				<thead className="transactions-table__head">
					<tr>
						<th className="transactions-table__date">Datum</th>
						<th className="transactions-table__amount">Částka</th>
						<th>Poznámka</th>
					</tr>
				</thead>
				<tbody className="transactions-table__body">
					{user.payments.length ? (
						user.payments.map((payment) => {
							return (
								<tr key={nanoid()}>
									<td className="transactions-table__date">
										{new Date(payment.dateOfPayment).toLocaleDateString()}
									</td>
									{startWith(payment.amount)}
									{infoMessage(payment)}
								</tr>
							);
						})
					) : (
						<tr>
							<td className="collection-table__empty" colSpan={8}>
								Nejsou k dispozici žádné transakce
							</td>
						</tr>
					)}
				</tbody>
				<tfoot className="transactions-table__foot">
					<tr>
						<td colSpan={3}>Celkem {user.amount} Kč</td>
					</tr>
				</tfoot>
			</table>
		</div>
	);
};

export default TransactionsTable;
