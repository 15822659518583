import React from 'react';
import logo from './../assets/icons/logo_fildy.svg';
import { ReactComponent as Facebook } from './../assets/icons/facebook.svg';
import { ReactComponent as Linkedin } from './../assets/icons/linkedin.svg';
import { ReactComponent as Youtube } from './../assets/icons/youtube.svg';

const Footer = () => {
	return (
		<footer className="footer" role="contentinfo">
			<div className="row-main row-main--wider">
				<div className="footer__wrap">
					<div className="footer__logo">
						<img src={logo} alt="Fildy logo" className="footer__logo-img"></img>
					</div>
					<div className="footer__nav-wrap">
						<nav role="navigation">
							<ul className="footer__nav-list">
								<li className="footer__nav-item">
									<a href="http://fildy.cz/o-nas/">O nás</a>
								</li>
								<li className="footer__nav-item">
									<a href="http://fildy.cz/blog/">Blog</a>
								</li>
								<li className="footer__nav-item">
									<a href="http://fildy.cz/kontakty/">Kontakt</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div className="footer__wrap">
					<div className="footer__copyright">
						<hr />
						<p>
							Copyright 2021
							<br />
							Náměstí republiky 1079/1A, 110 00 Nové město - Praha 1, CZ
						</p>
					</div>
					<div className="footer__socs">
						<p>
							<a href="mailto:info@fildy.cz">info@fildy.cz</a>
						</p>
						<p>
							<a href="tel:+420800777777">+420 800 777 777</a>
						</p>
						<div className="footer__socs-wrap">
							<a className="footer__socs-link" href="https://www.facebook.com/">
								<Facebook />
							</a>
							<a className="footer__socs-link" href="https://www.linkedin.com/">
								<Linkedin />
							</a>
							<a className="footer__socs-link" href="https://www.youtube.com/">
								<Youtube />
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
