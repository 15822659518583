import { fetchThisUser } from '../api/usersApi';

export const loadThisUsers = (id) => async (dispatch) => {
	dispatch({ type: 'USER_LOADING' });
	try {
		const res = await fetchThisUser(id);
		dispatch({ type: 'USER_LOADED', payload: res.data.data.data });
	} catch {
		dispatch({ type: 'USER_FAIL' });
	}
};
