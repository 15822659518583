const initState = {
	isLoading: false,
};

const stampReducer = (state = initState, action) => {
	switch (action.type) {
		case 'STAMP_LOADING':
			return {
				...state,
				isLoading: true,
			};
		case 'STAMP_LOADED':
			return {
				...state,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default stampReducer;
