import React, { useState } from 'react';
import Input from '../Inputs/Input/Input';
import Button from '../Inputs/Button/Button';

const Step2 = (props) => {
	const { values, handleChange, handleChangeNumber } = props;
	const [error, setError] = useState(false);


	const nextStep = (e) => {
		if (values.firstName == '' || values.lastName == '' || values.phone == '' || values.email == '' || values.street == '' || values.streetNumber == '' || values.city == '' || values.postCode == '') {
			setError(true);
		} else {
			setError(false);
			e.preventDefault();
			props.nextStep();
		}
	};

	const prevStep = (e) => {
		e.preventDefault();
		props.prevStep();
	};

	return (
		<div className="row-main">
			<div className="register-form">
				<h2 className="register-form__title small-padding">
					Pojďme se trochu seznámit
				</h2>
				<p className="register-form__text register-form__text--large">
					Abychom Váš mohli kontaktovat ohledně portfolia
				</p>
				<div className="row-main row-main--thinest">
					<div className="register-form__split-box">
						<Input
							label="Vaše jméno"
							type="text"
							name="firstName"
							onChange={handleChange('firstName')}
							defaultValue={values.firstName}
						/>
						<Input
							label="Vaše příjmení"
							type="text"
							name="lastName"
							onChange={handleChange('lastName')}
							defaultValue={values.lastName}
						/>
					</div>
					<Input
						label="Email"
						type="email"
						name="email"
						onChange={handleChange('email')}
						defaultValue={values.email}
					/>
					<Input
						label="Tel. číslo"
						type="number"
						name="phone"
						onChange={handleChangeNumber('phone')}
						defaultValue={values.phone}
					/>
				</div>
				<p className="register-form__text register-form__text--large">
					Kam budeme posílat známky? (Lze později změnit)
				</p>
				<div className="row-main row-main--thinest">
					<div className="register-form__split-box">
						<Input
							label="Ulice"
							type="text"
							name="street"
							onChange={handleChange('street')}
							defaultValue={values.street}
						/>
						<Input
							label="Číslo popisné"
							type="text"
							name="streetNumber"
							onChange={handleChange('streetNumber')}
							defaultValue={values.streetNumber}
						/>
					</div>
					<div className="register-form__split-box">
						<Input
							label="Město"
							type="text"
							name="city"
							onChange={handleChange('city')}
							defaultValue={values.city}
						/>
						<Input
							label="PSČ"
							type="number"
							name="postCode"
							onChange={handleChangeNumber('postCode')}
							defaultValue={values.postCode}
						/>
					</div>
					{error && <p className="input-input__error-form">Vyplňte chybějící pole</p>}
					<Button type="button" text="Pokračovat" onClick={nextStep} />
					<div className="u-text-center">
						<button
							className="register-form__go-back"
							type="button"
							onClick={prevStep}
						>
							Zpět
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Step2;
