import axios from 'axios';

const config = {
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true,
};

export const fetchUser = () => {
	const res = axios.get('/api/v1/users/me', config);
	return res;
};

export const registerUser = (frmData) => {
	const res = axios.post(
		'/api/v1/users/singup',
		frmData,
		config
	);
	return res;
};

export const loginUser = (frmData) => {
	const res = axios.post(
		'/api/v1/users/login',
		frmData,
		config
	);
	return res;
};

export const reqPasswordReset = (email) => {
	const res = axios.post(
		'/api/v1/users/forgotPassword',
		email,
		config
	);
	return res;
};

export const passwordReset = (token, body) => {
	const res = axios.patch(
		'/api/v1/users/resetPassword/' + token,
		body,
		config
	);
	return res;
};

export const updateUserPassword = (formData) => {
	const res = axios.patch(
		'/api/v1/users/updateMyPassword',
		formData,
		config
	);
	return res;
};

export const updateUserInfo = (formData) => {
	const res = axios.patch(
		'/api/v1/users/updateMe',
		formData,
		config
	);
	return res;
};
