import React, { useState } from 'react';
import InputWithUnit from '../Inputs/InputWithUnit/InputWithUnit';
import Button from '../Inputs/Button/Button';
import { useSelector, useDispatch } from 'react-redux';
import { requestRefundMoney } from '../../actions/paymentsActions';

const RefundMoneyModal = ({ setOpenModal }) => {
	const dispatch = useDispatch();
	const maximum = useSelector((state) => state.authReducers.user.amount);

	const [amount, setAmount] = useState();

	const hangleOnChange = (e) => {
		const { name, value } = e.target;

		switch (name) {
			case 'amount':
				setAmount(value);
				break;
			default:
				break;
		}
	};

	const handleOnSubmit = (e) => {
		e.preventDefault();
		dispatch(requestRefundMoney({ moneyBack: amount }));
	};

	return (
		<div className="modal__overlay">
			<div className="modal__block">
				<h2 className="modal__title">Vážně chcete vybrat naspořené peníze?</h2>
				<p>Níže vyberte, kolik peněz chcete vybrat.</p>
				<button
					className="modal__close"
					onClick={() => {
						setOpenModal(false);
					}}
				>
					×
				</button>
				<form
					className="modal__form"
					onSubmit={(e) => {
						handleOnSubmit(e);
						setOpenModal(false);
					}}
				>
					<InputWithUnit
						label="Kolik peněz chcete vybrat?"
						type="number"
						unit="Kč"
						name="amount"
						max={maximum}
						min="0"
						onChange={(e) => hangleOnChange(e)}
					/>
					<Button text="Přesto zažádat o výběr" type="submit" />
				</form>
			</div>
		</div>
	);
};

export default RefundMoneyModal;
