import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Input from '../Inputs/Input/Input';
import InputWithUnit from '../Inputs/InputWithUnit/InputWithUnit';
import Button from '../Inputs/Button/Button';

import { updateUserValue } from '../../actions/authActions';

const UpdateInfoModal = ({ setOpenModal }) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.authReducers.user);

	const [amount, setAmount] = useState(user.info.amount);
	const [howLong, setHowLong] = useState(user.info.howLong);

	const handleOnChange = (e) => {
		const { name, value } = e.target;

		switch (name) {
			case 'amount':
				setAmount(value);
				break;
			case 'howLong':
				setHowLong(value);
				break;
			default:
				break;
		}
	};

	const handleOnSubmit = (e) => {
		e.preventDefault();
		dispatch(updateUserValue({ amount, howLong }));
		setOpenModal(false);
	};

	return (
		<div className="modal__overlay">
			<div className="modal__block">
				<h2 className="modal__title">Upravit výši spoření</h2>
				<button
					className="modal__close"
					onClick={() => {
						setOpenModal(false);
					}}
				>
					×
				</button>
				<form className="modal__form" onSubmit={(e) => handleOnSubmit(e)}>
					<div className="register-form__split-box">
						<InputWithUnit
							label="Pravidelná částka"
							type="number"
							name="amount"
							unit="Kč"
							defaultValue={amount}
							min="0"
							onChange={(e) => handleOnChange(e)}
						/>
						<Input
							label="Na jak dlouho"
							type="text"
							name="howLong"
							defaultValue={howLong}
							onChange={(e) => handleOnChange(e)}
						/>
					</div>
					<Button text="Uložit změny" type="submit" />
				</form>
			</div>
		</div>
	);
};

export default UpdateInfoModal;
