import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editStamp, removeStamp } from '../actions/stampsActions';

const TableRow = ({ stamp, user, isAuth }) => {
	const dispatch = useDispatch();

	const [nameInput, setNameInput] = useState(false);
	const [order, setOrder] = useState(stamp.order);
	const [name, setName] = useState(stamp.name);
	const [image, setImage] = useState(stamp.image);
	const [price, setPrice] = useState(stamp.price);

	const handleOnChange = (e) => {
		const { name, value } = e.target;

		switch (name) {
			case 'order':
				setOrder(value);
				break;
			case 'name':
				setName(value);
				break;
			case 'image':
				setImage(e.target.files[0]);
				break;
			case 'price':
				setPrice(value);
				break;
			default:
				break;
		}
	};

	const handleOnSave = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('order', order);
		formData.append('name', name);
		formData.append('image', image);
		formData.append('price', price);
		dispatch(editStamp(stamp._id, formData, user._id));
	};

	const editOrder = (nameInput, stampOrder) => {
		if (nameInput) {
			return (
				<input
					type="number"
					name="order"
					defaultValue={stampOrder}
					onChange={(e) => handleOnChange(e)}
				/>
			);
		} else {
			return <>{stampOrder}.</>;
		}
	};

	const editImage = (nameInput, stampImage) => {
		if (nameInput) {
			return (
				<input
					type="file"
					name="image"
					defaultValue={stampImage}
					onChange={(e) => handleOnChange(e)}
				/>
			);
		} else {
			return (
				<img
					src={`/img/stamps/${stamp.image}`}
					alt="Položka"
				/>
			);
		}
	};

	const editName = (nameInput, stampName) => {
		if (nameInput) {
			return (
				<input
					type="text"
					name="name"
					defaultValue={stampName}
					onChange={(e) => handleOnChange(e)}
				/>
			);
		} else {
			return <>{stampName}</>;
		}
	};

	const editPrice = (nameInput, stampPrice) => {
		if (nameInput) {
			return (
				<input
					type="number"
					name="price"
					defaultValue={stampPrice}
					onChange={(e) => handleOnChange(e)}
				></input>
			);
		} else {
			return <>{stampPrice}</>;
		}
	};

	const remove = (e, stampId) => {
		e.preventDefault();
		dispatch(removeStamp(stampId, user._id));
	};

	const delivered = (e, stampId) => {
		e.preventDefault();
		dispatch({
			type: 'MODAL_DELIVERED',
			payload: { stampId: stampId, userId: user._id },
		});
	};

	const buy = (stamp) => {
		switch (stamp.paid) {
			case true:
				return <button disabled>Ve sbírce</button>;
			default:
				return (
					<button
						onClick={() => {
							dispatch({ type: 'MODAL_BUY', payload: stamp.id });
						}}
					>
						Přidat do sbírky
					</button>
				);
		}
	};

	const edit = (stamp) => {
		switch (stamp.paid) {
			case true:
				return '';
			default:
				return (
					<>
						<button
							onClick={(e) => {
								if (nameInput) {
									setNameInput(!nameInput);
									handleOnSave(e);
								} else {
									setNameInput(!nameInput);
								}
							}}
						>
							{nameInput ? 'Uložit' : 'Upravit'}
						</button>
						{nameInput ? (
							<button onClick={() => setNameInput(!nameInput)}>Zrušit</button>
						) : (
							<button onClick={(e) => remove(e, stamp._id)}>Smazat</button>
						)}
					</>
				);
		}
	};

	const delivery = (stamp) => {
		switch (stamp.delivery) {
			case 'post':
				return 'Poštou';
			case 'personal':
				return 'Osobně';
			case 'delivered':
				return 'Doručeno';
			default:
				return 'Neurčeno';
		}
	};

	return (
		<tr>
			{isAuth === 'admin' ? (
				<td>{editOrder(nameInput, stamp.order)}</td>
			) : (
				<td className="collection-table__hidden">&nbsp;</td>
			)}
			<td className="collection-table__image-box">
				{editImage(nameInput, stamp.img)}
			</td>
			<td>{editName(nameInput, stamp.name)}</td>
			<td>
				{Math.round(((100 * stamp.assignedAmount) / stamp.price) * 100) / 100} %
			</td>
			<td>
				{stamp.dateOfBuy ? new Date(stamp.dateOfBuy).toLocaleDateString() : '-'}
			</td>
			<td
				className={isAuth === 'admin' ? 'is-clickable' : ''}
				onClick={
					isAuth === 'admin' ? (e) => delivered(e, stamp._id) : undefined
				}
			>
				{delivery(stamp)}
			</td>
			<td>{editPrice(nameInput, stamp.price)} Kč</td>
			<td>{isAuth === 'admin' ? edit(stamp) : buy(stamp)}</td>
		</tr>
	);
};

export default TableRow;
