export const isInRegister = () => (dispatch) => {
	dispatch({ type: 'IN_REGISTER' });
};

export const registerStep = (step) => (dispatch) => {
	dispatch({ type: `REGISTER_STEP_${step}` });
};

export const isNotInRegister = () => (dispatch) => {
	dispatch({ type: 'NOT_IN_REGISTER' });
};
