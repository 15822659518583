import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { newStamp } from '../actions/stampsActions';

const TableRowAdd = ({ user }) => {
	const dispatch = useDispatch();

	const [name, setName] = useState('');
	const [image, setImage] = useState();
	const [price, setPrice] = useState('');
	const [order, setOrder] = useState(Object.keys(user.stamps).length + 1);

	const handleOnChange = (e) => {
		const { name, value, files } = e.target;

		switch (name) {
			case 'name':
				setName(value);
				break;
			case 'image':
				setImage(files[0]);
				break;
			case 'price':
				setPrice(value);
				break;
			case 'order':
				setOrder(value);
				break;
			default:
				break;
		}
	};

	const handleOnSave = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('name', name);
		if (image) {
			formData.append('image', image);
		} else {
			formData.append('image', 'default.jpg');
		}
		formData.append('price', price);
		formData.append('order', order);
		formData.append('user', user._id);
		dispatch(newStamp(user._id, formData));
		setName('');
		setImage('');
		setPrice('');
		setOrder(parseInt(order) + 1);
	};

	const content = (
		<>
			<td>
				<input
					type="number"
					name="order"
					placeholder="Pořadí"
					value={order}
					onChange={(e) => handleOnChange(e)}
				></input>
			</td>
			<td>
				<input
					type="file"
					name="image"
					accept="image/*"
					onChange={(e) => handleOnChange(e)}
				/>
			</td>
			<td>
				<input
					type="text"
					name="name"
					placeholder="Název"
					value={name}
					onChange={(e) => handleOnChange(e)}
				/>
			</td>
			<td></td>
			<td></td>
			<td></td>
			<td>
				<input
					type="number"
					name="price"
					placeholder="Cena"
					value={price}
					onChange={(e) => handleOnChange(e)}
				></input>
			</td>
			<td>
				<button onClick={(e) => handleOnSave(e)}>Přidat</button>
			</td>
		</>
	);
	return <tr className="collection-table__add">{content}</tr>;
};

export default TableRowAdd;
