import React from 'react';

const AboutUs = () => {
	return (
		<div className="about-us">
			<div className="row-main">
				<h2>
					Konkrétní portfolio vám sestavíme podle aktuální
					<br />
					situace na trhu a příležitostí
				</h2>
				<div className="about-us__info">
					<div className="about-us__photo">
						<img
							loading="lazy"
							src="/images/pytela_sr.png"
							alt="Miloš Pytela"
						/>
					</div>
					<div className="about-us__text">
						<h3>Miloš Pytela</h3>
						<p>
							Sbírání se věnuje více než 50 let a navázal tak na rodinnou tradici kterou začal jeho otec. Filatelie se pak stala nejen jeho zálibou ale i povoláním. Sám rozšiřuje sbírku známek a dopisů Kanady kterou začal jeho otec a vlastní sbírku Rakouska uherska. Pro naše klienty sestavuje portfolia a vybírá investiční příležitosti pro naše klienty.
						</p>
						{/* <ul>
							<li>znalecké posudky a odhady cen ve filatelii</li>
							<li>
								poradenství při nákupu/prodeji a v oblasti investic ve filatelii
							</li>
						</ul> */}
					</div>
				</div>
				<p className="about-us__contact">
					Chcete se mě na cokoliv zeptat? Zavolejte mi na +420
					<strong> 777 459 460</strong>
				</p>
			</div>
		</div>
	);
};

export default AboutUs;
