import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { resetPassword } from '../actions/authActions';

import Button from '../components/Inputs/Button/Button';
import Alert from '../components/Alert';

const ResetPassword = () => {
	const dispatch = useDispatch();
	const alert = useSelector((state) => state.alertReducers);

	const { token } = useParams();

	const [newPassword, setNewPassword] = useState();
	const [newPasswordConfirm, setNewPasswordConfirm] = useState();

	const handleOnChange = (e) => {
		const { name, value } = e.target;

		switch (name) {
			case 'password':
				setNewPassword(value);
				break;
			case 'confirmPassword':
				setNewPasswordConfirm(value);
				break;
			default:
				break;
		}
	};

	const handleOnSubmit = (e) => {
		e.preventDefault();
		dispatch(resetPassword(token, newPassword, newPasswordConfirm));
	};

	return (
		<>
			{alert.message ? <Alert type={alert.type} message={alert.message} /> : ''}
			<div className="row-main">
				<div className="register-form">
					<h2 className="register-form__title">Nastavení nového hesla</h2>
					<form
						className="row-main row-main--thinest"
						onSubmit={(e) => handleOnSubmit(e)}
					>
						<div className="input-input">
							<label className={`input-input__label`}>Nové heslo</label>
							<input
								className={`input-input__field`}
								type="password"
								name="password"
								placeholder="Zadejte nové heslo"
								onChange={(e) => {
									handleOnChange(e);
								}}
							/>
							<input
								className={`input-input__field`}
								type="password"
								name="confirmPassword"
								placeholder="Zadejte nové heslo znovu"
								onChange={(e) => {
									handleOnChange(e);
								}}
							/>
							<Button
								type="submit"
								text="Uložit nové heslo"
								loading={alert.isLoading}
							/>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default ResetPassword;
