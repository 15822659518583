import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import Nav from './Header/Nav';

const Header = (props) => {
	let location = useLocation();

	return (
		<header className="header" role="banner">
			<div className="row-main row-main--wider">
				<div className="header__wrap">
					<div className="header__item header__item--left">
						{location.pathname === '/' ? (
							<a className="header__logo" href="https://fildy.cz/">
								Fildy – Filatelie pro každého
							</a>
						) : (
							<Link className="header__logo" to="/">
								Fildy – Filatelie pro každého
							</Link>
						)}
					</div>
					<div className="header__item header__item--right">
						<Nav active={props.active} />
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
