import React, { useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { useSelector } from 'react-redux';

import TableRow from '../components/TableRow';
import TableRowAdd from '../components/TableRowAdd';

const CollectionTable = ({ user }) => {
	const isAuth = useSelector((state) => state.authReducers.user.role);
	const stampLoading = useSelector((state) => state.stampReducers.isLoading);
	const [stampLength, setStampLength] = useState(false);

	useEffect(() => {
		setStampLength(user.stamps);
	}, [user]);

	return (
		<div
			className={`overflow-element collection-table__loading ${
				stampLoading ? 'is-loading' : ''
			}`}
		>
			<span className="spinner"></span>
			<table className="collection-table">
				<thead className="collection-table__head">
					<tr>
						{isAuth === 'admin' ? (
							<th className="collection-table__order">Pořadí</th>
						) : (
							<th className="collection-table__hidden">&nbsp;</th>
						)}
						<th className="collection-table__image"></th>
						<th className="collection-table__name">Položka</th>
						<th className="collection-table__assigned">Naspořeno</th>
						<th className="collection-table__day-of-buy">Získáno</th>
						<th className="collection-table__delivery">Doručení</th>
						<th className="collection-table__price">Kupní cena</th>
						<th className="collection-table__settings"></th>
					</tr>
				</thead>
				<tbody className="collection-table__body">
					{isAuth === 'admin' && <TableRowAdd user={user} isAuth={isAuth} />}
					{stampLength && stampLength.length ? (
						stampLength.map((stamp) => {
							return (
								<TableRow
									stamp={stamp}
									user={user}
									isAuth={isAuth}
									key={nanoid()}
								/>
							);
						})
					) : (
						<tr>
							<td className="collection-table__empty" colSpan={8}>
								Nejsou k dispozici žádné položky
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CollectionTable;
