import React, { useState } from 'react';
import './InputWithUnit.scss';

const InputWithUnit = (props) => {
	const [clicked, setClicked] = useState(false);
	const { onChange, defaultValue, label, type, unit, name, max, min } = props;

	return (
		<div className="input-input-with-unit">
			<label
				className={`input-input-with-unit__label ${clicked ? 'is-active' : ''}`}
			>
				{label}
			</label>
			<input
				className={`input-input-with-unit__field ${clicked ? 'is-active' : ''}`}
				type={type}
				name={name}
				placeholder={label}
				value={defaultValue}
				max={max}
				min={min}
				onChange={(e) => {
					e.target.value ? setClicked(true) : setClicked(false);
					if (onChange) onChange(e);
				}}
			/>
			<span className="input-input-with-unit__unit">{unit}</span>
		</div>
	);
};

export default InputWithUnit;
