import axios from 'axios';

const config = {
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true,
};

export const buyStampApi = (id) => {
	const res = axios.patch(
		'/api/v1/stamps/' + id + '/payStamp',
		undefined,
		config
	);
	return res;
};

export const createStamp = (formData) => {
	const res = axios.post(
		`/api/v1/stamps/`,
		formData,
		config
	);
	return res;
};

export const updateStamp = (id, formData, userId) => {
	const res = axios.patch(
		'/api/v1/stamps/' + id + '/' + userId,
		formData,
		config
	);
	return res;
};

export const deleteStamp = (id, userId) => {
	const res = axios.delete(
		'/api/v1/stamps/' + id + '/' + userId,
		config
	);
	return res;
};

export const updateDelivery = (id, delivery) => {
	const res = axios.patch(
		'/api/v1/stamps/' + id + '/updateDelivery',
		{ delivery: delivery },
		config
	);
	return res;
};
