import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Input from '../Inputs/Input/Input';
import InputWithUnit from '../Inputs/InputWithUnit/InputWithUnit';
import Textarea from '../Inputs/Textarea/Textarea';
import Button from '../Inputs/Button/Button';

const Step1 = (props) => {
	const { values, handleChange, handleChangeNumber } = props;

	const [error, setError] = useState(false);


	const nextStep = (e) => {
		if (values.amount == '' || values.howLong == '' || values.howFoundUs == '' || values.attracts == '' || values.experiences == '') {
			setError(true);
		} else {
			setError(false);
			e.preventDefault();
			props.nextStep();
		}
	};

	return (
		<div className="row-main">
			<div className="register-form">
				<h1 className="register-form__title small-padding">
					Vytvořte si profil pro správu portfolia, ve kterém uvidíte:
				</h1>
				<div>
					<ul className="register-form__list">
						<li className="register-form__item" data-text="Vaše portfolio">
							Vaše portfolio
						</li>
						<li className="register-form__item" data-text="Na co právě spoříte">
							Na co právě spoříte
						</li>
						<li
							className="register-form__item"
							data-text="Kolik vám chybí ke splácení známky"
						>
							Kolik vám chybí ke splácení známky
						</li>
					</ul>
					<p className="register-form__text">
						Také máte přístup k mimořádným možnostem nákupu (novinky, horké
						zboží, extra příležitosti).
						<br />V rámci profilu vám poradíme, co můžete dle vašich možností a
						přání sbírat, aby to splnilo očekávání
					</p>
				</div>
				<div className="register-form__second">
					<h2 className="register-form__title no-padding">
						Pojďme se trochu seznámit
					</h2>
					<p className="register-form__text register-form__text--large">
						Kolik plánujete měsíčně spořit a na jak dlouho? (lze kdykoliv
						změnit)
					</p>
				</div>
				<div className="row-main row-main--thinest">
					<div className="register-form__split-box">
						<InputWithUnit
							label="Pravidelná částka"
							type="number"
							unit="Kč"
							onChange={handleChangeNumber('amount')}
							defaultValue={values.amount}
						/>
						<Input
							label="Na jak dlouho"
							type="text"
							onChange={handleChange('howLong')}
							defaultValue={values.howLong}
						/>
					</div>
					<Textarea
						label="Jak jste nás našel"
						onChange={handleChange('howFoundUs')}
						defaultValue={values.howFoundUs}
					/>
					<Textarea
						label="Co vás na filatelii láká?"
						onChange={handleChange('attracts')}
						defaultValue={values.attracts}
					/>
					<Textarea
						label="Jaké máte s filatelií zkušenosti?"
						onChange={handleChange('experiences')}
						defaultValue={values.experiences}
					/>
					{error && <p className="input-input__error-form">Vyplňte chybějící pole</p>}
					<Button type="button" text="Pokračovat" onClick={nextStep} />
					<div className="u-text-center">
						<Link className="register-form__go-back" to="/">
							Již mám účet
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Step1;
