import {
	buyStampApi,
	updateStamp,
	deleteStamp,
	updateDelivery,
	createStamp,
} from '../api/stampsApi';
import { fetchThisUser } from '../api/usersApi';
import { fetchUser } from '../api/authApi';

export const buyStamp = (id, delivery) => async (dispatch) => {
	dispatch({ type: 'STAMP_LOADING' });
	try {
		await buyStampApi(id);
		dispatch(updateDeliveryStamp(id, delivery));
		const res = await fetchUser();
		dispatch({ type: 'AUTH_SUCCESS', payload: res.data.data });
		dispatch({ type: 'STAMP_LOADED' });
		dispatch({ type: 'ALERT_BUY_STAMP_S' });
	} catch (err) {
		dispatch({ type: 'STAMP_LOADED' });
		dispatch({ type: 'ALERT_BUY_STAMP_E', payload: err.response.data.message });
	}

	setTimeout(() => {
		dispatch({ type: 'ALERT_RESET' });
	}, 3000);
};

export const newStamp = (id, body) => async (dispatch) => {
	dispatch({ type: 'STAMP_LOADING' });
	try {
		await createStamp(body);
		const res = await fetchThisUser(id);
		dispatch({ type: 'USER_LOADED', payload: res.data.data.data });
		dispatch({ type: 'STAMP_LOADED' });
	} catch {}
};

export const editStamp = (id, body, userId) => async (dispatch) => {
	dispatch({ type: 'STAMP_LOADING' });
	try {
		await updateStamp(id, body, userId);
		const res = await fetchThisUser(userId);
		dispatch({ type: 'USER_LOADED', payload: res.data.data.data });
		dispatch({ type: 'STAMP_LOADED' });
	} catch {}
};
export const removeStamp = (id, userId) => async (dispatch) => {
	dispatch({ type: 'STAMP_LOADING' });
	try {
		await deleteStamp(id, userId);
		const res = await fetchThisUser(userId);
		dispatch({ type: 'USER_LOADED', payload: res.data.data.data });
		dispatch({ type: 'STAMP_LOADED' });
	} catch {}
};

export const updateDeliveryStamp =
	(id, delivery, userId) => async (dispatch) => {
		dispatch({ type: 'STAMP_LOADING' });
		try {
			await updateDelivery(id, delivery);
			if (userId) {
				const res = await fetchThisUser(userId);
				dispatch({ type: 'USER_LOADED', payload: res.data.data.data });
			}
			dispatch({ type: 'STAMP_LOADED' });
		} catch {}
	};
