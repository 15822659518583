import axios from 'axios';
import {
	fetchUser,
	loginUser,
	registerUser,
	updateUserInfo,
	updateUserPassword,
	reqPasswordReset,
	passwordReset,
} from '../api/authApi';

export const isAuth = () => async (dispatch) => {
	dispatch({ type: 'AUTH_LOADING' });
	try {
		const res = await fetchUser();
		dispatch({ type: 'AUTH_SUCCESS', payload: res.data.data });
	} catch {
		dispatch({ type: 'AUTH_ERROR' });
	}
};

export const login = (email, password) => async (dispatch) => {
	dispatch({ type: 'LOGIN_LOADING' });

	const body = JSON.stringify({ email, password });

	try {
		const res = await loginUser(body);
		dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
		dispatch(isAuth());
	} catch (err) {
		dispatch({ type: 'LOGIN_FAIL' });
		dispatch({ type: 'ALERT_LOGIN_E', payload: err.response.data.message });
	}

	setTimeout(() => {
		dispatch({ type: 'ALERT_RESET' });
	}, 3000);
};

export const register = (registerData) => async (dispatch) => {
	const body = JSON.stringify({
		firstName: registerData.firstName,
		lastName: registerData.lastName,
		email: registerData.email,
		phone: registerData.phone,
		address: {
			street: registerData.street,
			streetNumber: registerData.streetNumber,
			city: registerData.city,
			postCode: registerData.postCode,
		},
		info: {
			amount: registerData.amount,
			howLong: registerData.howLong,
			howFoundUs: registerData.howFoundUs,
			attracts: registerData.attracts,
			experiences: registerData.experiences,
		},
		password: registerData.password,
		passwordConfirm: registerData.passwordConfirm,
	});

	try {
		const res = await registerUser(body);
		dispatch({ type: 'REGISTER_SUCCESS', payload: res.data });
		dispatch(isAuth());
	} catch {
		dispatch({ type: 'REGISTER_FAIL' });
	}
};

export const logout = () => async (dispatch) => {
	const res = await axios.get('/api/v1/users/logout', {
		withCredentials: true,
	});
	dispatch({ type: 'LOGOUT_SUCCESS', payload: res.data.data });
};

export const forgotPassword = (email) => async (dispatch) => {
	const body = JSON.stringify({ email });
	dispatch({ type: 'ALERT_LOADING' });

	try {
		const res = await reqPasswordReset(body);
		dispatch({
			type: 'ALERT_FORGOT_PASSWORD_S',
			payload: res.data.message,
		});
	} catch (err) {
		dispatch({
			type: 'ALERT_FORGOT_PASSWORD_E',
			payload: err.response.data.message,
		});
	}

	setTimeout(() => {
		dispatch({ type: 'ALERT_RESET' });
	}, 3000);
};

export const resetPassword =
	(token, newPassword, newPasswordConfirm) => async (dispatch) => {
		console.log(token, newPassword, newPasswordConfirm);
		const body = JSON.stringify({
			password: newPassword,
			passwordConfirm: newPasswordConfirm,
		});
		dispatch({ type: 'ALERT_LOADING' });

		try {
			await passwordReset(token, body);
			dispatch({ type: 'ALERT_RESET_PASSWORD_S' });
		} catch (err) {
			dispatch({
				type: 'ALERT_RESET_PASSWORD_E',
				payload: err.response.data.message,
			});
		}

		setTimeout(() => {
			dispatch({ type: 'ALERT_RESET' });
		}, 3000);
	};

export const changePassword =
	({ passwordCurrent, password, passwordConfirm }) =>
	async (dispatch) => {
		const body = JSON.stringify({ passwordCurrent, password, passwordConfirm });

		try {
			await updateUserPassword(body);
			dispatch({ type: 'ALERT_PROFILE_CHANGE_PASSWORD_S' });
			dispatch(logout());
		} catch {
			dispatch({ type: 'ALERT_PROFILE_CHANGE_PASSWORD_E' });
		}

		setTimeout(() => {
			dispatch({ type: 'ALERT_RESET' });
		}, 3000);
	};

export const updateUser =
	({
		firstName,
		lastName,
		email,
		phone,
		street,
		streetNumber,
		city,
		postCode,
		account,
	}) =>
	async (dispatch) => {
		const body = JSON.stringify({
			firstName,
			lastName,
			email,
			phone,
			address: {
				street,
				streetNumber,
				city,
				postCode,
			},
			account,
		});

		try {
			await updateUserInfo(body);
			dispatch({ type: 'ALERT_PROFILE_CHANGE_S' });
			dispatch(isAuth());
		} catch {
			dispatch({ type: 'ALERT_PROFILE_CHANGE_E' });
		}

		setTimeout(() => {
			dispatch({ type: 'ALERT_RESET' });
		}, 3000);
	};

export const updateUserValue =
	({ amount, howLong }) =>
	async (dispatch) => {
		const body = JSON.stringify({
			info: {
				amount,
				howLong,
			},
		});

		try {
			await updateUserInfo(body);
			dispatch({ type: 'ALERT_PROFILE_CHANGE_S' });
			dispatch(isAuth());
		} catch {
			dispatch({ type: 'ALERT_PROFILE_CHANGE_E' });
		}

		setTimeout(() => {
			dispatch({ type: 'ALERT_RESET' });
		}, 3000);
	};
