import React, { useEffect } from 'react';
import RegisterForm from '../components/RegisterForm/RegisterForm';
import { isInRegister } from '../actions/registerActions';
import store from '../store';

const Register = () => {
	useEffect(() => {
		store.dispatch(isInRegister());
	}, []);

	return (
		<>
			<RegisterForm />
		</>
	);
};

export default Register;
