import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { register } from '../../actions/authActions';
import { registerStep } from '../../actions/registerActions';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const RegisterForm = () => {
	const isAuthenticated = useSelector(
		(state) => state.authReducers.isAuthenticated
	);
	const dispatch = useDispatch();

	const [page, setPage] = useState(1);
	const [accountCreated, setAccountCreated] = useState(false);
	const [error, setError] = useState(false);
	const [formData, setFormData] = useState({
		amount: '',
		howLong: '',
		howFoundUs: '',
		attracts: '',
		experiences: '',
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		street: '',
		streetNumber: '',
		city: '',
		postCode: '',
		password: '',
		passwordConfirm: '',
	});

	const nextStep = () => {
		setPage((currPage) => currPage + 1);
		dispatch(registerStep(page + 1));
		window.scrollTo(0, 0);
	};

	const prevStep = () => {
		setPage((currPage) => currPage - 1);
		dispatch(registerStep(page - 1));
		window.scrollTo(0, 0);
	};

	const handleChange = (input) => (e) => {
		setFormData({ ...formData, [input]: e.target.value });
	};

	const handleChangeNumber = (input) => (e) => {
		setFormData({ ...formData, [input]: parseInt(e.target.value) });
	};

	const onSubmit = (e) => {
		if (formData.password == '' || formData.passwordConfirm == '') {
			e.preventDefault();
			setError(true);
		} else {
			setError(false);
			e.preventDefault();
			dispatch(register(formData));
			setAccountCreated(true);
		}
	};

	if (isAuthenticated) return <Redirect to="/collection" />;

	if (accountCreated) return <Redirect to="/" />;

	const PageDisplay = () => {
		switch (page) {
			case 1:
				return (
					<Step1
						nextStep={nextStep}
						handleChange={handleChange}
						handleChangeNumber={handleChangeNumber}
						values={formData}
					/>
				);
			case 2:
				return (
					<Step2
						nextStep={nextStep}
						prevStep={prevStep}
						handleChange={handleChange}
						handleChangeNumber={handleChangeNumber}
						values={formData}
					/>
				);
			case 3:
				return (
					<Step3
						prevStep={prevStep}
						handleChange={handleChange}
						values={formData}
						error={error}
					/>
				);
			default:
				break;
		}
	};

	return (
		<main className="main" role="main">
			<form onSubmit={(e) => onSubmit(e)}>{PageDisplay()}</form>
		</main>
	);
};

export default RegisterForm;
