import axios from 'axios';

const config = {
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true,
};

export const refundMoney = (frmData) => {
	const res = axios.patch(
		'/api/v1/users/moneyBack',
		frmData,
		config
	);
	return res;
};
