import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../actions/authActions';

import Input from '../components/Inputs/Input/Input';
import Button from '../components/Inputs/Button/Button';
import Alert from '../components/Alert';

const Login = () => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.authReducers);
	const alert = useSelector((state) => state.alertReducers);

	const [email, setEmail] = useState();
	const [password, setPassword] = useState();

	const hangleOnChange = (e) => {
		const { name, value } = e.target;

		switch (name) {
			case 'email':
				setEmail(value);
				break;
			case 'password':
				setPassword(value);
				break;
			default:
				break;
		}
	};

	const handleOnSubmit = (e) => {
		e.preventDefault();
		dispatch(login(email, password));
	};

	if (user.isAuthenticated && user.user.role === 'user') {
		return <Redirect to="/collection" />;
	} else if (user.isAuthenticated && user.user.role === 'admin') {
		return <Redirect to="/users" />;
	}

	return (
		<>
			{alert.message ? <Alert type={alert.type} message={alert.message} /> : ''}
			<div className="row-main">
				<div className="login">
					<h2 className="login__title">Přihlášení</h2>
					<form className="from" onSubmit={(e) => handleOnSubmit(e)}>
						<div className="row-main row-main--thinest">
							<Input
								label="E-mail"
								type="email"
								name="email"
								noValidate="true"
								onChange={(e) => hangleOnChange(e)}
							/>
							<Input
								label="Heslo"
								type="password"
								name="password"
								noValidate="true"
								onChange={(e) => hangleOnChange(e)}
							/>
							<Button
								type="submit"
								text="Přihlásit se"
								loading={user.isLoading}
							/>
							<div className="u-text-center">
								<Link className="register-form__go-back" to="/forgotpassword">
									Zapoměl jsem heslo
								</Link>
							</div>
							<div className="u-text-center">
								<Link className="register-form__go-back" to="/register">
									Registrovat se
								</Link>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default Login;
