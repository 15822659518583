const initalState = {
	isAuthenticated: null,
	isLoading: false,
	user: null,
};

const authReducer = (state = initalState, action) => {
	switch (action.type) {
		case 'AUTH_LOADING':
		case 'LOGIN_LOADING':
			return {
				...state,
				isLoading: true,
			};
		case 'AUTH_SUCCESS':
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				user: action.payload.data,
			};
		case 'LOGIN_SUCCESS':
		case 'REGISTER_SUCCESS':
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				user: action.payload.data.user,
			};
		case 'AUTH_ERROR':
		case 'LOGIN_FAIL':
		case 'LOGOUT_SUCCESS':
		case 'REGISTER_FAIL':
			return {
				...state,
				isLoading: false,
				isAuthenticated: false,
				user: null,
			};
		default:
			return state;
	}
};

export default authReducer;
