import React from 'react';
import './Button.scss';

const Button = (props) => {
	return (
		<div className="u-text-center">
			<button
				className="input-button"
				type={props.type}
				onClick={props.onClick}
				data-text={props.text}
			>
				{props.loading ? <span className="button-loader"></span> : props.text}
			</button>
		</div>
	);
};

export default Button;
