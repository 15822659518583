import React from 'react';
import Transaction from '../components/Transactions/Transaction';

const Transactions = () => {
	return (
		<>
			<main className="main" role="main">
				<Transaction />
			</main>
		</>
	);
};

export default Transactions;
