const initalState = {
	isLoading: false,
	users: null,
};

const usersReducer = (state = initalState, action) => {
	switch (action.type) {
		case 'USERS_LOADING':
			return {
				...state,
				isLoading: true,
			};
		case 'USERS_LOADED':
			return {
				...state,
				isLoading: false,
				users: action.payload,
			};
		case 'USERS_FAIL':
			return {
				...state,
				isLoading: false,
				users: null,
			};
		default:
			return state;
	}
};

export default usersReducer;
