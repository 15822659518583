import React from 'react';
import ProfileForm from '../components/ProfileForm/ProfileForm';

const Profile = () => {
	return (
		<>
			<ProfileForm />
		</>
	);
};

export default Profile;
