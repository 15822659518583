import { combineReducers } from 'redux';
import authReducers from './authReducers';
import registerReducers from './registerReducers';
import usersReducers from './usersReducers';
import userReducers from './userReducers';
import stampReducers from './stampReducers';
import alertReducers from './alertReducers';
import modalReducers from './modalReducers';

export default combineReducers({
	authReducers,
	registerReducers,
	usersReducers,
	userReducers,
	stampReducers,
	alertReducers,
	modalReducers,
});
