const initalState = {
	isLoading: false,
	user: null,
};

const userReducer = (state = initalState, action) => {
	switch (action.type) {
		case 'USER_LOADING':
			return {
				...state,
				isLoading: true,
			};
		case 'USER_LOADED':
			return {
				...state,
				isLoading: false,
				user: action.payload,
			};
		case 'USER_FAIL':
			return {
				...state,
				isLoading: false,
				user: null,
			};
		default:
			return state;
	}
};

export default userReducer;
