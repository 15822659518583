import { refundMoney } from '../api/paymentsApi';
import { isAuth } from './authActions';

export const requestRefundMoney = (frmData) => async (dispatch) => {
	try {
		await refundMoney(frmData);
		dispatch({ type: 'ALERT_REFUND_MONEY_S' });
		dispatch(isAuth());
	} catch (err) {
		if (err.response.status === 400) {
			dispatch({
				type: 'ALERT_REFUND_MONEY_E',
				payload: err.response.data.message,
			});
		} else {
			dispatch({ type: 'ALERT_REFUND_MONEY_E' });
		}
	}

	setTimeout(() => {
		dispatch({ type: 'ALERT_RESET' });
	}, 3000);
};
