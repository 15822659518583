import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { buyStamp } from '../../actions/stampsActions';

import Input from '../Inputs/Input/Input';
import Button from '../Inputs/Button/Button';

const BuyStampModal = ({ id }) => {
	const dispatch = useDispatch();
	const address = useSelector((state) => state.authReducers.user.address);
	const [delivery, setDelivery] = useState('personal');
	const [street, setStreet] = useState(address.street);
	const [streetNumber, setStreetNumber] = useState(address.streetNumber);
	const [city, setCity] = useState(address.city);
	const [postCode, setPostCode] = useState(address.postCode);

	const handleOnChange = (e) => {
		const { name, value } = e.target;

		switch (name) {
			case 'delivery':
				setDelivery(value);
				break;
			case 'street':
				setStreet(value);
				break;
			case 'streetNumber':
				setStreetNumber(value);
				break;
			case 'city':
				setCity(value);
				break;
			case 'postCode':
				setPostCode(value);
				break;
			default:
				break;
		}
	};

	const handleOnSubmit = (e) => {
		e.preventDefault();
		dispatch(buyStamp(id, delivery));
	};

	return (
		<div className="modal__overlay">
			<div className="modal__block">
				<h2 className="modal__title">Vyberte si způsob doručení</h2>
				<button
					className="modal__close"
					onClick={() => {
						dispatch({ type: 'MODAL_CLOSE' });
					}}
				>
					×
				</button>
				<form
					className="modal__form"
					onSubmit={(e) => {
						handleOnSubmit(e);
						dispatch({ type: 'MODAL_CLOSE' });
					}}
				>
					<select
						className="input-select"
						name="delivery"
						onChange={(e) => handleOnChange(e)}
					>
						<option value="personal">Osobně</option>
						<option value="post">Poštou</option>
					</select>
					{delivery === 'post' && (
						<div className="modal__delivery">
							<p>Položka bude doručena na tuto adresu:</p>
							<div className="register-form__split-box">
								<Input
									label="Ulice"
									type="text"
									name="street"
									defaultValue={street}
									onChange={(e) => handleOnChange(e)}
								/>
								<Input
									label="Číslo popisné"
									type="text"
									name="streetNumber"
									defaultValue={streetNumber}
									onChange={(e) => handleOnChange(e)}
								/>
							</div>
							<Input
								label="Město"
								type="text"
								name="city"
								defaultValue={city}
								onChange={(e) => handleOnChange(e)}
							/>
							<Input
								label="PSČ"
								type="text"
								name="postCode"
								defaultValue={postCode}
								onChange={(e) => handleOnChange(e)}
							/>
						</div>
					)}
					<Button text="Přidat do sbírky" type="submit" />
				</form>
			</div>
		</div>
	);
};

export default BuyStampModal;
