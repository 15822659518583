import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../actions/authActions';

import { ReactComponent as User } from '../../assets/icons/user.svg';
import { ReactComponent as Users } from '../../assets/icons/people.svg';
import { ReactComponent as Collection } from '../../assets/icons/suitcase-black-shape.svg';
import { ReactComponent as Transactions } from '../../assets/icons/arrows.svg';
import { ReactComponent as Logout } from '../../assets/icons/logout.svg';

const Nav = () => {
	let location = useLocation();

	const isAuthenticated = useSelector(
		(state) => state.authReducers.isAuthenticated
	);
	const headerStep = useSelector((state) => state.registerReducers);
	const userRole = useSelector((state) => state.authReducers.user);
	const dispatch = useDispatch();

	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		setIsActive(false);
	}, [userRole]);

	const logoutt = (e) => {
		e.preventDefault();
		dispatch(logout());
	};

	const adminLinks = (
		<>
			<li className="header__link">
				<Link
					className={`header__anchor header__anchor--icon ${
						location.pathname === '/users' ? 'is-active' : ''
					}`}
					data-text="Uživatelé"
					to="/users"
				>
					<span className="header__icon header__icon--users">
						<Users />
					</span>
					Uživatelé
				</Link>
			</li>
			<li className="header__link">
				<Link
					className={`header__anchor header__anchor--icon ${
						location.pathname === '/profile' ? 'is-active' : ''
					}`}
					data-text="Moje údaje"
					to="/profile"
				>
					<span className="header__icon header__icon--profile">
						<User />
					</span>
					Moje údaje
				</Link>
			</li>
			<li className="header__link">
				<Link
					className="header__anchor header__anchor--icon"
					data-text="Odhlásit se"
					to="/"
					onClick={(e) => logoutt(e)}
				>
					<span className="header__icon header__icon--logout">
						<Logout />
					</span>
					Odhlásit se
				</Link>
			</li>
		</>
	);

	const adminLinksMobile = (
		<>
			<li
				className="header__link header__link-mobile--toggle"
				onClick={() => {
					setIsActive(!isActive);
				}}
			>
				MENU
			</li>
			<li
				className={`header__link header__link-mobile ${
					isActive ? 'is-active' : ''
				}`}
				onClick={() => {
					setIsActive(!isActive);
				}}
			>
				<Link
					className={`header__anchor header__anchor--icon ${
						location.pathname === '/users' ? 'is-active' : ''
					}`}
					data-text="Uživatelé"
					to="/users"
				>
					<span className="header__icon header__icon--users">
						<Users />
					</span>
					Uživatelé
				</Link>
			</li>
			<li
				className={`header__link header__link-mobile ${
					isActive ? 'is-active' : ''
				}`}
				onClick={() => {
					setIsActive(!isActive);
				}}
			>
				<Link
					className={`header__anchor header__anchor--icon ${
						location.pathname === '/profile' ? 'is-active' : ''
					}`}
					data-text="Moje údaje"
					to="/profile"
				>
					<span className="header__icon header__icon--profile">
						<User />
					</span>
					Moje údaje
				</Link>
			</li>
			<li
				className={`header__link header__link-mobile ${
					isActive ? 'is-active' : ''
				}`}
				onClick={() => {
					setIsActive(!isActive);
				}}
			>
				<Link
					className="header__anchor header__anchor--icon"
					data-text="Odhlásit se"
					to="/"
					onClick={(e) => logoutt(e)}
				>
					<span className="header__icon header__icon--logout">
						<Logout />
					</span>
					Odhlásit se
				</Link>
			</li>
		</>
	);

	const authorizedLinks = (
		<>
			<li className="header__link">
				<Link
					className={`header__anchor header__anchor--icon ${
						location.pathname === '/collection' ? 'is-active' : ''
					}`}
					data-text="Sbírka"
					to="/collection"
				>
					<span className="header__icon header__icon--profile">
						<Collection />
					</span>
					Sbírka
				</Link>
			</li>
			<li className="header__link">
				<Link
					className={`header__anchor header__anchor--icon ${
						location.pathname === '/transactions' ? 'is-active' : ''
					}`}
					data-text="Transakce"
					to="/transactions"
				>
					<span className="header__icon header__icon--profile">
						<Transactions />
					</span>
					Transakce
				</Link>
			</li>
			<li className="header__link">
				<Link
					className={`header__anchor header__anchor--icon ${
						location.pathname === '/profile' ? 'is-active' : ''
					}`}
					data-text="Moje údaje"
					to="/profile"
				>
					<span className="header__icon header__icon--profile">
						<User />
					</span>
					Moje údaje
				</Link>
			</li>
			<li className="header__link">
				<Link
					className="header__anchor header__anchor--icon"
					data-text="Odhlásit se"
					to="/"
					onClick={(e) => logoutt(e)}
				>
					<span className="header__icon header__icon--logout">
						<Logout />
					</span>
					Odhlásit se
				</Link>
			</li>
		</>
	);

	const authorizedLinksMobile = (
		<>
			<li
				className="header__link header__link-mobile--toggle"
				onClick={() => {
					setIsActive(!isActive);
				}}
			>
				MENU
			</li>
			<li
				className={`header__link header__link-mobile ${
					isActive ? 'is-active' : ''
				}`}
				onClick={() => {
					setIsActive(!isActive);
				}}
			>
				<Link
					className={`header__anchor header__anchor--icon ${
						location.pathname === '/collection' ? 'is-active' : ''
					}`}
					data-text="Sbírka"
					to="/collection"
				>
					<span className="header__icon header__icon--profile">
						<Collection />
					</span>
					Sbírka
				</Link>
			</li>
			<li
				className={`header__link header__link-mobile ${
					isActive ? 'is-active' : ''
				}`}
				onClick={() => {
					setIsActive(!isActive);
				}}
			>
				<Link
					className={`header__anchor header__anchor--icon ${
						location.pathname === '/transactions' ? 'is-active' : ''
					}`}
					data-text="Transakce"
					to="/transactions"
				>
					<span className="header__icon header__icon--profile">
						<Transactions />
					</span>
					Transakce
				</Link>
			</li>
			<li
				className={`header__link header__link-mobile ${
					isActive ? 'is-active' : ''
				}`}
				onClick={() => {
					setIsActive(!isActive);
				}}
			>
				<Link
					className={`header__anchor header__anchor--icon ${
						location.pathname === '/profile' ? 'is-active' : ''
					}`}
					data-text="Moje údaje"
					to="/profile"
				>
					<span className="header__icon header__icon--profile">
						<User />
					</span>
					Moje údaje
				</Link>
			</li>
			<li
				className={`header__link header__link-mobile ${
					isActive ? 'is-active' : ''
				}`}
				onClick={() => {
					setIsActive(!isActive);
				}}
			>
				<Link
					className="header__anchor header__anchor--icon"
					data-text="Odhlásit se"
					to="/"
					onClick={(e) => logoutt(e)}
				>
					<span className="header__icon header__icon--logout">
						<Logout />
					</span>
					Odhlásit se
				</Link>
			</li>
		</>
	);

	const registerLinks = (
		<>
			<li className="header__link">
				<Link
					className={`header__anchor ${
						headerStep.step === 1 ? 'is-active' : ''
					}`}
					data-text="Sbírka"
				>
					Informace
				</Link>
			</li>
			<li className="header__link">
				<Link
					className={`header__anchor ${
						headerStep.step === 2 ? 'is-active' : ''
					}`}
					data-text="Transakce"
				>
					Kontakt
				</Link>
			</li>
			<li className="header__link">
				<Link
					className={`header__anchor ${
						headerStep.step === 3 ? 'is-active' : ''
					}`}
					data-text="Moje údaje"
				>
					Heslo
				</Link>
			</li>
		</>
	);

	return (
		<>
			<nav role="navigation" className="nav--desktop">
				<ul className="header__list">
					{isAuthenticated && userRole.role === 'user' ? authorizedLinks : ''}
					{isAuthenticated && userRole.role === 'admin' ? adminLinks : ''}
					{location.pathname === '/register' ? registerLinks : ''}
				</ul>
			</nav>
			<nav role="navigation" className="nav--mobile">
				<ul className="header__list">
					{isAuthenticated && userRole.role === 'user'
						? authorizedLinksMobile
						: ''}
					{isAuthenticated && userRole.role === 'admin' ? adminLinksMobile : ''}
				</ul>
			</nav>
		</>
	);
};

export default Nav;
