import { fetchAllUsers } from '../api/usersApi';

export const loadAllUsers = () => async (dispatch) => {
	dispatch({ type: 'USERS_LOADING' });
	try {
		const res = await fetchAllUsers();
		dispatch({ type: 'USERS_LOADED', payload: res.data.data.data });
	} catch {
		dispatch({ type: 'USERS_FAIL' });
	}
};
