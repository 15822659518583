import React from 'react';

const PageLoader = () => {
	return (
		<div className="loader">
			<div className="loader__inner">
				<p className="loader__text">Načítání</p>
				<div className="loader__spinner"></div>
			</div>
		</div>
	);
};

export default PageLoader;
