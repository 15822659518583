import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import PageLoader from '../components/PageLoader';
import CollectionTable from '../components/CollectionTable';
import TransactionTable from '../components/TransactionsTable';
import SetAsDeliveredModal from '../components/Modals/SetAsDeliveredModal';

const User = () => {
	const user = useSelector((state) => state.userReducers.user);
	const isLoading = useSelector((state) => state.userReducers.isLoading);
	const modal = useSelector((state) => state.modalReducers);

	const [open, setOpen] = useState(false);

	const openCloseBox = () => {
		setOpen(!open);
	};

	const content = isLoading ? (
		<PageLoader />
	) : (
		<main className="main" role="main">
			<div className="row-main user">
				<h2 className="user__title">
					{user.firstName} {user.lastName}
				</h2>
				<div className="user__split-container">
					<div className="user__split-box">
						<p>
							<strong>Kolik spoří:</strong>
						</p>
						<p>{user.info.amount} Kč</p>
						<p>
							<strong>Na jak dlouho:</strong>{' '}
						</p>
						<p>{user.info.howLong}</p>
					</div>
					<div className="user__split-box">
						<p>
							<strong>Adresa:</strong>
						</p>
						<p>
							{user.address.street} {user.address.streetNumber},{' '}
							{user.address.city}, {user.address.postCode}
						</p>
						<p>
							<strong>Kontakt:</strong>
						</p>
						<p>
							<a href={`mailto:${user.email}`}>{user.email}</a>
							<br />
							<a href={`tel:${user.phone}`}>{user.phone}</a>
						</p>
					</div>
				</div>

				<div className="user__payment-info">
					<p onClick={openCloseBox}>Další informace</p>
					{open && (
						<div className="user__split-container">
							<div className="user__split-box">
								<p>
									<strong>Číslo účtu:</strong>
								</p>
								<p>{user.account}</p>
								<p>
									<strong>Jak nás našel:</strong>
								</p>
								<p>{user.info.howFoundUs}</p>
								<p>
									<strong>Co od filatelie očekává:</strong>
								</p>
								<p>{user.info.attracts}</p>
							</div>
							<div className="user__split-box">
								<p>
									<strong>Variabilní symbol:</strong>
								</p>
								<p>{user.variableSymbol}</p>
								<p>
									<strong>Jak má zkušenosti:</strong>
								</p>
								<p>{user.info.experiences}</p>
							</div>
						</div>
					)}
				</div>

				<CollectionTable user={user} />
			</div>
			<div className="row-main row-main--thinest user">
				<TransactionTable user={user} />
			</div>
			{modal.isOpen && modal.type === 'deliveredmodal' && (
				<SetAsDeliveredModal stampId={modal.stampId} userId={modal.userId} />
			)}
		</main>
	);

	return <>{content}</>;
};

export default User;
