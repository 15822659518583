import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { forgotPassword } from '../../actions/authActions';

import Alert from '../Alert';
import Button from '../Inputs/Button/Button';

const ForgotPasswordForm = () => {
	const dispatch = useDispatch();
	const alert = useSelector((state) => state.alertReducers);
	const [email, setEmail] = useState('');

	const onSubmit = (e) => {
		e.preventDefault();
		dispatch(forgotPassword(email));
		setEmail('');
	};

	const handleChange = (e) => {
		setEmail(e.target.value);
	};

	return (
		<>
			{alert.message ? <Alert type={alert.type} message={alert.message} /> : ''}
			<div className="row-main">
				<div className="register-form">
					<h2 className="register-form__title">Obnovení hesla</h2>
					<form
						className="row-main row-main--thinest"
						onSubmit={(e) => onSubmit(e)}
					>
						<div className="input-input">
							<label className={`input-input__label`}>E-mail</label>
							<input
								className={`input-input__field`}
								type="email"
								placeholder="Zadejte váš email"
								value={email}
								onChange={(e) => {
									handleChange(e);
								}}
							/>
						</div>
						<Button
							type="submit"
							text="Poslat nové heslo"
							loading={alert.isLoading}
						/>
						<div className="u-text-center">
							<Link className="register-form__go-back" to="/">
								Přihlásit se
							</Link>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default ForgotPasswordForm;
