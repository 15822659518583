import React, { useState } from 'react';
import './Textarea.scss';

const Textarea = (props) => {
	const [clicked, setClicked] = useState(false);
	const { onChange, defaultValue, label } = props;

	return (
		<div className="input-textarea">
			<label className={`input-textarea__label ${clicked ? 'is-active' : ''}`}>
				{label}
			</label>
			<textarea
				className={`input-textarea__field ${clicked ? 'is-active' : ''}`}
				placeholder={label}
				value={defaultValue}
				onChange={(e) => {
					e.target.value ? setClicked(true) : setClicked(false);
					onChange(e);
				}}
			></textarea>
		</div>
	);
};

export default Textarea;
