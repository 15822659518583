import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoutes';
import Register from '../views/Register';
import ForgotPassword from '../views/ForgotPassword';
import ResetPassword from '../views/ResetPassword';
import Collection from '../views/Collection';
import Transactions from '../views/Transactions';
import Profile from '../views/Profile';
import Users from '../views/Users';
import User from '../views/User';

const Routes = () => {
	return (
		<div>
			<Switch>
				<Route exact path="/register" component={Register} />
				<Route exact path="/forgotPassword" component={ForgotPassword} />
				<Route exact path="/resetPassword/:token" component={ResetPassword} />
				<PrivateRoute exact path="/collection" component={Collection} />
				<PrivateRoute exact path="/transactions" component={Transactions} />
				<PrivateRoute exact path="/profile" component={Profile} />
				<PrivateRoute exact path="/users" component={Users} />
				<PrivateRoute exact path="/user" component={User} />
			</Switch>
		</div>
	);
};

export default Routes;
