import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Login from './views/Login';
import Routes from './utils/Routes';

import { Provider } from 'react-redux';
import store from './store';

import Layout from './components/Layout';
import { isAuth } from './actions/authActions';

const App = () => {
	useEffect(() => {
		store.dispatch(isAuth());
	}, []);

	return (
		<Provider store={store}>
			<Router>
				<Layout>
					<Switch>
						<Route exact path="/" component={Login} />
						<Route component={Routes} />
					</Switch>
				</Layout>
			</Router>
		</Provider>
	);
};

export default App;
