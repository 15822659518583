const initalState = {
	inRegister: false,
	step: null,
};

const registerReducer = (state = initalState, action) => {
	switch (action.type) {
		case 'IN_REGISTER':
			return {
				...state,
				inRegister: true,
				step: 1,
			};
		case 'REGISTER_STEP_1':
			return {
				...state,
				step: 1,
			};
		case 'REGISTER_STEP_2':
			return {
				...state,
				step: 2,
			};
		case 'REGISTER_STEP_3':
			return {
				...state,
				step: 3,
			};
		case 'NOT_IN_REGISTER':
			return {
				...state,
				inRegister: false,
				step: null,
			};
		default:
			return state;
	}
};

export default registerReducer;
