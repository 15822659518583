import axios from 'axios';

const config = {
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true,
};

export const fetchAllUsers = () => {
	const res = axios.get('/api/v1/users', config);
	return res;
};

export const fetchThisUser = (id) => {
	const res = axios.get('/api/v1/users/' + id, config);
	return res;
};
