import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UpdateInfoModal from '../Modals/UpdateInfoModal';
import BuyStampModal from '../Modals/BuyStampModal';
import Alert from '../Alert';

import CollectionTable from '../CollectionTable';

const Collection = () => {
	const user = useSelector((state) => state.authReducers.user);
	const alert = useSelector((state) => state.alertReducers);
	const modal = useSelector((state) => state.modalReducers);

	const [updateInfoModalOpen, setUpdateInfoModalOpen] = useState(false);

	return (
		<>
			{alert.message ? <Alert type={alert.type} message={alert.message} /> : ''}
			<div className="collection">
				<div className="row-main row-main--thiner">
					<h1 className="collection__title">Dobrý den {user.firstName},</h1>
					<p className="collection__text">
						toto je vaše portfolio při spoření{' '}
						<span
							onClick={() => {
								setUpdateInfoModalOpen(true);
							}}
							className="collection__savings"
						>
							{user.info.amount} Kč
						</span>{' '}
						měsíčně
					</p>

					<CollectionTable user={user} />
				</div>
				{updateInfoModalOpen && (
					<UpdateInfoModal setOpenModal={setUpdateInfoModalOpen} />
				)}
				{modal.isOpen && modal.type === 'buymodal' && (
					<BuyStampModal id={modal.stampId} />
				)}
			</div>
		</>
	);
};

export default Collection;
