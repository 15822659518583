import React, { useState, useEffect } from 'react';
import './Input.scss';

const Input = (props) => {
	const [clicked, setClicked] = useState(false);
	const [error, setError] = useState('');
	const { onChange, defaultValue, label, type, name, noValidate } = props;

	useEffect(() => {
		defaultValue ? setClicked(true) : setClicked(false);
	}, [defaultValue]);

	const validate = (input, e) => {
		const mailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
		const phoneRegex =
			/^(\+?420)?(2[0-9]{2}|3[0-9]{2}|4[0-9]{2}|5[0-9]{2}|72[0-9]|73[0-9]|77[0-9]|60[1-8]|56[0-9]|70[2-5]|79[0-9])[0-9]{3}[0-9]{3}$/;
		const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
		if (input === 'firstName') {
			if (e.target.value === '') {
				setError('Jméno je povinné');
			} else {
				setError(false);
			}
		}
		if (input === 'lastName') {
			if (e.target.value === '') {
				setError('Příjmení je povinné');
			} else {
				setError(false);
			}
		}
		if (input === 'email') {
			if (e.target.value === '') {
				setError('Email je povinný');
			} else if (!e.target.value.match(mailRegex)) {
				setError('Email není platný');
			} else {
				setError(false);
			}
		}
		if (input === 'phone') {
			if (e.target.value === '') {
				setError('Tel. číslo je povinné');
			} else if (!e.target.value.match(phoneRegex)) {
				setError('Tel. číslo není platné');
			} else {
				setError(false);
			}
		}
		if (input === 'street') {
			if (e.target.value === '') {
				setError('Adresa je povinná');
			} else {
				setError(false);
			}
		}
		if (input === 'streetNumber') {
			if (e.target.value === '') {
				setError('Číslo popisné je povinné');
			} else {
				setError(false);
			}
		}
		if (input === 'city') {
			if (e.target.value === '') {
				setError('Město je povinné');
			} else {
				setError(false);
			}
		}
		if (input === 'postCode') {
			if (e.target.value === '') {
				setError('PSČ je povinné');
			} else {
				setError(false);
			}
		}
		if (input === 'confirmationPassword') {
			if (e.target.value === '') {
				setError('Zopakujte prosím heslo');
			} else if (
				e.target.value !==
				document.querySelector('input[name="password"]').value
			) {
				setError('Hesla se neshodují');
			} else {
				setError(false);
			}
		}
		if (input === 'password') {
			if (e.target.value === '') {
				setError('Heslo je povinné');
			} else if (e.target.value.length < 8) {
				setError('Heslo musí mít nejméně 8 znaků');
			} else if (!e.target.value.match(passwordRegex)) {
				setError(
					'Heslo musí obsahovat nejméně jeden velký znak, jeden malý znak a jedno číslo'
				);
			} else {
				setError(false);
			}
		}
	};

	return (
		<div className="input-input">
			<label className={`input-input__label ${clicked ? 'is-active' : ''}`}>
				{label}
			</label>
			<input
				className={`input-input__field ${clicked ? 'is-active' : ''}`}
				type={type}
				name={name}
				placeholder={label}
				value={defaultValue}
				onChange={(e) => {
					e.target.value ? setClicked(true) : setClicked(false);
					if (onChange) {
						onChange(e);
					}
					if (!noValidate) {
						validate(name, e);
					}
				}}
			/>
			{error && <p className="input-input__error">{error}</p>}
		</div>
	);
};

export default Input;
