import React from 'react';
import CollectionInner from '../components/Collection/Collection';
import AboutUs from '../components/AboutUs/AboutUs';

const Collection = () => {
	return (
		<>
			<main className="main" role="main">
				<CollectionInner />
				<AboutUs />
			</main>
		</>
	);
};

export default Collection;
