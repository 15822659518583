import React, { useEffect } from 'react';
import { nanoid } from 'nanoid';
import { loadAllUsers } from '../actions/usersActions';
import { loadThisUsers } from '../actions/userActions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Users = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const users = useSelector((state) => state.usersReducers.users);

	useEffect(() => {
		dispatch(loadAllUsers());
	}, [dispatch]);

	const run = (id) => {
		dispatch(loadThisUsers(id));
		history.push('/user');
	};

	return (
		<main className="main" role="main">
			<div className="row-main users">
				<h2 className="users__title">Uživatelé</h2>
				<div className="overflow-element">
					<table className="users__table">
						<thead>
							<tr>
								<th>Jméno</th>
								<th>Adresa</th>
								<th>Kolik spoří</th>
								<th>Na jak dlouho</th>
							</tr>
						</thead>
						<tbody>
							{users &&
								users.map((user) => {
									return (
										<tr onClick={() => run(user._id)} key={nanoid()}>
											<td>
												{user.firstName} {user.lastName}
											</td>
											<td>
												{user.address.city}, {user.address.street}{' '}
												{user.address.streetNumber}, {user.address.postCode}
											</td>
											<td className="users__table--amount">
												{user.info.amount} Kč
											</td>
											<td className="users__table--long">
												{user.info.howLong}
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>
		</main>
	);
};

export default Users;
