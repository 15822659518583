import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoutes = ({ component: Component, ...rest }) => {
	const user = useSelector((state) => state.authReducers);

	return (
		<Route
			{...rest}
			render={(props) =>
				!user.isAuthenticated && !user.isLoading ? (
					<Redirect to="/" />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

export default PrivateRoutes;
